var render = function (_h,_vm) {
var _obj;
var _c=_vm._c;return _c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:({
        el: _vm.props.pluginElement,
        props: {
            kiwi: Object.assign({}, _vm.props.pluginProps)
        }
    }),expression:"{\n        el: props.pluginElement,\n        props: {\n            kiwi: {\n                ...props.pluginProps,\n            }\n        }\n    }"}],class:( _obj = {}, _obj[_vm.data.staticClass] = _vm.data.staticClass, _obj )})}
var staticRenderFns = []

export { render, staticRenderFns }