import { render, staticRenderFns } from "./MessageListMessageModern.vue?vue&type=template&id=5742de84&scoped=true&functional=true&"
import script from "./MessageListMessageModern.vue?vue&type=script&lang=js&"
export * from "./MessageListMessageModern.vue?vue&type=script&lang=js&"
import style0 from "./MessageListMessageModern.vue?vue&type=style&index=0&id=5742de84&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5742de84",
  null
  
)

export default component.exports